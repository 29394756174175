var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('ValidationObserver',{ref:"validation-observer"},[_c('b-row',[_c('b-col',{staticClass:"px-1",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Название","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","size":"md","placeholder":"Введите"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('b-col',{staticClass:"px-1",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Описание","label-for":"subtitle"}},[_c('b-form-input',{attrs:{"id":"subtitle","size":"md","placeholder":"Введите"},model:{value:(_vm.subtitle),callback:function ($$v) {_vm.subtitle=$$v},expression:"subtitle"}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"px-1",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Локаль","label-for":"locale"}},[_c('b-form-select',{attrs:{"id":"locale","options":_vm.localeOptions},model:{value:(_vm.locale),callback:function ($$v) {_vm.locale=$$v},expression:"locale"}})],1)],1),_c('b-col',{staticClass:"px-1",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Ссылка","label-for":"link"}},[_c('b-form-input',{attrs:{"id":"link","size":"md","placeholder":"Введите"},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Тип баннера","label-for":"type"}},[_c('b-form-select',{attrs:{"id":"type","options":_vm.typeOptions},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Изображение","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Изображение"}},[_c('VueFileAgent',{ref:"vueFileAgent",attrs:{"deletable":true,"meta":true,"accept":'image/*,.zip',"maxSize":'5MB',"maxFiles":1,"helpText":'Выберите изображение',"errorText":{
                                    type: 'Неверный тип файла. Разрешены только изображения или zip',
                                    size: 'Размер файла не должен превышать 5 МБ.',
                                }},on:{"beforedelete":function($event){return _vm.onBeforeDelete($event)}},model:{value:(_vm.fileRecords),callback:function ($$v) {_vm.fileRecords=$$v},expression:"fileRecords"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1)],1),_c('b-button',{staticClass:"btn-success float-right mt-2",attrs:{"disabled":_vm.isSaving},on:{"click":_vm.save}},[_vm._v(" Сохранить ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }